* { box-sizing: border-box; }

html, body {
  font-size: calc(10px + 2vmin);
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  color:#cacaca;
  font-weight: 100;
  text-transform: lowercase;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background-color: #262122;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

h1 {
  margin: 1rem 0 0;
  padding: 0;
  font-weight: 100;
  font-size: 2.5rem;
}
h2 {
  margin: 0;
  padding: 0;
  font-weight: 100;
  font-size:1.3rem;
}

p, ol, ul {
  font-size: 0.8rem;
}

ol {
  margin: 0.5rem 0 0;
  padding: 0;
  list-style: none;
}
ol li {
  display: inline-block;
  padding: 0 0.5rem;
  position: relative;
}

ol li::before {
  content: "";
  display: block;
  width:5px;
  height: 5px;
  border-radius: 5px;
  background-color: rgba(232,215,92,1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

ol li:first-of-type::before{
  display: none;
}

::selection {
  background: rgba(232,215,92,0.4);
}